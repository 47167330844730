<template>
	<nav class="nav">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					예약 완료
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<div id="container" class="container container--fit" v-if="bookings.length > 0">
		<div id="sub" class="sub booking">
			<div class="box">
				<!-- 예약 숙소 -->
				<section class="booking-top">
					<div class="box box--reverse">
						<h2 class="booking-top__title booking-top__title--small">예약이 완료되었습니다.</h2>
						<div class="booking-top__date">
							<div class="flex">
								<span>{{bookings[0].BOOKING_DATETIME}}</span>
							</div>
						</div>
					</div>
				</section>
				<section class="content">
					<!-- 숙소 리스트 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<div class="booking-total">
								<h3 class="booking-total__title">
									상품 정보
								</h3>
								<div class="booking-total__desc">
									통합예약번호
									<span>{{bookings[0].BOOKING_NO}}</span>
								</div>
							</div>
							<!-- 예약 숙소 1 -->
							<div class="booking-item" v-for="booking in bookings" :key="booking.SEQ">
								<div class="booking-item__date">
									예약번호 <span>{{booking.BOOKING_NO}}</span>
								</div>
								<div class="booking-item__badge">숙소</div>
								<h3 class="booking-item__title">{{booking.HOTEL_NM}}</h3>
								<p class="booking-top__desc">
									<span class="booking-top__badge">{{booking.PROD_TYPE}}</span>
									{{booking.PROD_NM}}
								</p>
								<div class="booking-item__date">
									{{booking.checkin}} ~ {{booking.checkout}}<br>
									{{booking.VISIT_MEANS}}
								</div>
								<div class="booking-item__price">
									<strong>{{booking.SALES_AMT.toLocaleString("en-US")}}</strong>원
								</div>
								<div class="booking-item__select" v-if="booking.CANCEL_AVAIL_DATE.length !== 0 && availableCancel">
									<span class="description red">
										{{booking.CANCEL_AVAIL_DATE}}까지 무료 취소 가능
									</span>
								</div>
							</div>
							<!-- 예약 숙소 2 -->
						</div>
					</article>
					<article class="booking-box">
						<div class="box box--reverse">
							<!-- 예약자 정보 -->
							<div class="booking-box__item">
								<h3 class="booking-box__title"> 예약자 정보 </h3>
								<ul class="info info--large">
									<li>
										<h4 class="info__title">
											이름
										</h4>
										<div class="info__text">
											{{bookings[0].ISSUE_NM}}
										</div>
									</li>
									<li>
										<h4 class="info__title">
											휴대폰 번호
										</h4>
										<div class="info__text">
											{{bookings[0].ISSUE_HP}}
										</div>
									</li>
								</ul>
							</div>
							<!-- 방문자 정보 -->
							<div class="booking-box__item">
								<h3 class="booking-box__title"> 방문자 정보 </h3>
								<ul class="info info--large">
									<li>
										<h4 class="info__title">
											이름
										</h4>
										<div class="info__text">
											{{bookings[0].GUEST_NM}}
										</div>
									</li>
									<li>
										<h4 class="info__title">
											휴대폰 번호
										</h4>
										<div class="info__text">
											{{bookings[0].GUEST_HP}}
										</div>
									</li>
								</ul>
							</div>
						</div>
					</article>
					<!-- 금액 및 결제 정보 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<h3 class="booking-box__title">금액 및 결제 정보</h3>
							<ul class="info">
								<li>
									<h4 class="info__title">
										결제수단
									</h4>
									<div class="info__text">
										{{bookings[0].PAY_TYPE}}
									</div>
								</li>
								<li>
									<h4 class="info__title">
										예약금액
									</h4>
									<div class="info__text">
										{{salesamt_tot[3].toLocaleString("en-US")}}
									</div>
								</li>
								<li>
									<h4 class="info__title">
										쿠폰
									</h4>
									<div class="info__text">
										{{salesamt_tot[0] ? "-" : ""}}{{salesamt_tot[0].toLocaleString("en-US")}}원
									</div>
								</li>
								<li>
									<h4 class="info__title">
										포인트
									</h4>
									<div class="info__text">
										{{salesamt_tot[1] ? "-" : ""}}{{salesamt_tot[1].toLocaleString("en-US")}}P
									</div>
								</li>
								<li>
									<h4 class="info__title">
										제휴할인
									</h4>
									<div class="info__text">
										{{salesamt_tot[2] ? "-" : ""}}{{salesamt_tot[2].toLocaleString("en-US")}}원
									</div>
								</li>
								<li class="point">
									<h4 class="info__title"> 결제금액 </h4>
									<div class="info__wrap">
										<div class="info__text"><strong class="red">{{(salesamt_tot[4]).toLocaleString("en-US")}}</strong>원</div>
									</div>
								</li>
							</ul>
						</div>
					</article>
					<!-- 적립예정 내역 -->
					<article class="booking-box booking-box--last">
						<div class="box box--reverse">
							<h3 class="booking-box__title">적립예정내역</h3>
							<ul class="info" v-if="pay03.length > 0">
								<li>
									<h4 class="info__title">
										포인트
									</h4>
									<div class="info__text">
										{{pay03[0].plan_point}}P
									</div>
								</li>
								<li>
									<h4 class="info__title">
										스탬프
									</h4>
									<div class="info__text">
										{{pay03[0].plan_stamp}}개
									</div>
								</li>
							</ul>
							<p class="complete-notice description">
								※ 퇴실 24시간 이후 포인트/스탬프가 지급됩니다.<br>
								<span>
									(대실 상품은 스탬프가 지급되지 않습니다.)
								</span>
							</p>
							<div class="booking-box__button">
								<div class="btns">
									<router-link :to="'/order/detail/' + bookings[0].BOOKING_NO + '?seq=' + bookings[0].SEQ" custom v-slot="{navigate}">
										<button type="button" class="btn btn-primary purple" @click="navigate">
											예약내역으로
										</button>
									</router-link>
									<router-link to="/" custom v-slot="{navigate}">
										<button type="button" class="btn btn-confirm" @click="navigate">
											홈으로
										</button>
									</router-link>
								</div>
							</div>
						</div>
					</article>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';

import { ref } from "vue"
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useRoute, useRouter } from 'vue-router';
import { onMounted } from '@vue/runtime-core';

dayjs.locale("ko");

export default {
	setup() {
		const router = useRouter();
		const route = useRoute();
		const store = useStore();
		const salesamt_tot = ref([])
		const userData = computed(() => store.state.userData);
		const bookings = computed(() => {
			const findeds = store.state.mmy02.mmy0202[route.params.id];
			if(findeds !== undefined && findeds.length > 0) {
				return findeds.map(finded => ({
					...finded,
					BOOKING_DATETIME: dayjs(finded.BOOKING_DATETIME).format("YY.MM.DD(dd) HH:mm"),
					checkin: dayjs(finded.SDATE).format("YYYY.MM.DD (dd)") + " " + finded.CHECKIN_TIME,
					checkout: finded.SDATE === finded.EDATE ?  finded.CHECKOUT_TIME : dayjs(finded.EDATE).format("YYYY.MM.DD (dd)") + " " + finded.CHECKOUT_TIME,
				}));
			}else {
				return [];
			}
		});
		onMounted(() => {
			store.dispatch("mmy02/fetchMmy0202", { proc_cd: "01", mem_id: userData.value.mem_token, booking_no: route.params.id }).then(x=>{
				//console.log(x.data.Res_data)
				var total_cp = ''
				var total_pt = ''
				var total_ot = ''
				var total_amt = ''
				for(var i = 0; i < x.data.Res_data.length; i++ ){
					if(total_cp === 0){
						total_cp = Number(x.data.Res_data[i].DC_AMT_CP);
					}else{
						total_cp = Number(total_cp) + Number(x.data.Res_data[i].DC_AMT_CP);
					}
					if(total_pt === 0){
						total_pt = Number(x.data.Res_data[i].DC_AMT_PT);
					}else{
						total_pt = Number(total_pt) + Number(x.data.Res_data[i].DC_AMT_PT);
					}
					if(total_ot === 0){
						total_ot = Number(x.data.Res_data[i].DC_AMT_OT);
					}else{
						total_ot = Number(total_ot) + Number(x.data.Res_data[i].DC_AMT_OT);
					}
					if(total_amt === 0){
						total_amt = Number(x.data.Res_data[i].PAID_AMT);
					}else{
						total_amt = Number(total_amt) + Number(x.data.Res_data[i].PAID_AMT);
					}
				}
				var result_amt = total_amt - (total_cp + total_pt + total_ot)
				if(result_amt < 0){
					result_amt = 0
				}
				salesamt_tot.value.push(total_cp,total_pt,total_ot,total_amt,result_amt);
				//console.log(salesamt_tot.value)
			});
		})
		return {
			bookings,
			goBack:() => {
				router.back();
			},
			pay03: computed(() => store.state.pay03.pay03),
			salesamt_tot,
		};
	},
}
</script>